import IconProhibition from "@/icons/icon_ prohibition.svg";
import Icon404 from "@/icons/icon_404.svg";
import IconEraseCircleOut from "@/icons/icon_ERASE_CIRCLE_OUT.svg";
import IconEraseDownOut from "@/icons/icon_ERASE_DOWN_OUT.svg";
import IconEraseLeftOut from "@/icons/icon_ERASE_LEFT_OUT.svg";
import IconEraseRightOut from "@/icons/icon_ERASE_RIGHT_OUT.svg";
import IconEraseUpOut from "@/icons/icon_ERASE_UP_OUT.svg";
import IconFadeBlackOut from "@/icons/icon_FADE_BLACK_OUT.svg";
import IconFadeCollapseIn from "@/icons/icon_FADE_COLLAPSE_IN.svg";
import IconFadeCrossOutIn from "@/icons/icon_FADE_CROSS_OUT_IN.svg";
import IconFadeExpendOut from "@/icons/icon_FADE_EXPEND_OUT.svg";
import IconFadeWhiteOut from "@/icons/icon_FADE_WHITE_OUT.svg";
import IconPushDownupOutIn from "@/icons/icon_PUSH_DOWNUP_OUT_IN.svg";
import IconPushLeftOutIn from "@/icons/icon_PUSH_LEFT_OUT_IN.svg";
import IconPushRightOutIn from "@/icons/icon_PUSH_RIGHT_OUT_IN.svg";
import IconPushUpdownOutIn from "@/icons/icon_PUSH_UPDOWN_OUT_IN.svg";
import IconSplitHorizontalOut from "@/icons/icon_SPLIT_HORIZONTAL_OUT.svg";
import IconSplitVerticalOut from "@/icons/icon_SPLIT_VERTICAL_OUT.svg";
import IconAccount from "@/icons/icon_account.svg";
import IconAdd from "@/icons/icon_add.svg";
import IconAiModelPositionButton from "@/icons/icon_ai_model_position_button.svg";
import IconAiModelScaleButton from "@/icons/icon_ai_model_scale_button.svg";
import IconAiScriptGeneratorCreateVideo from "@/icons/icon_ai_script_generator_create_video.svg";
import IconAiScriptGeneratorTopicInput from "@/icons/icon_ai_script_generator_topic_input.svg";
import IconAistudios from "@/icons/icon_aistudios.svg";
import IconAlign from "@/icons/icon_align.svg";
import IconAlign22 from "@/icons/icon_align_22.svg";
import IconAlignBottom from "@/icons/icon_align_bottom.svg";
import IconAlignCenter from "@/icons/icon_align_center.svg";
import IconAlignLeft from "@/icons/icon_align_left.svg";
import IconAlignMiddle from "@/icons/icon_align_middle.svg";
import IconAlignRight from "@/icons/icon_align_right.svg";
import IconAlignTop from "@/icons/icon_align_top.svg";
import IconAmoonCommentCots from "@/icons/icon_amoon_comment_cots.svg";
import IconAnimate from "@/icons/icon_animate.svg";
import IconAnimateFadeIn from "@/icons/icon_animate_fade_in.svg";
import IconAnimateFadeOut from "@/icons/icon_animate_fade_out.svg";
import IconAnimateInDown from "@/icons/icon_animate_in_down.svg";
import IconAnimateInLeft from "@/icons/icon_animate_in_left.svg";
import IconAnimateInRight from "@/icons/icon_animate_in_right.svg";
import IconAnimateInUp from "@/icons/icon_animate_in_up.svg";
import IconAnimateNo from "@/icons/icon_animate_no.svg";
import IconAnimateOutDown from "@/icons/icon_animate_out_down.svg";
import IconAnimateOutLeft from "@/icons/icon_animate_out_left.svg";
import IconAnimateOutRight from "@/icons/icon_animate_out_right.svg";
import IconAnimateOutUp from "@/icons/icon_animate_out_up.svg";
import IconAnimateZoomIn from "@/icons/icon_animate_zoom_in.svg";
import IconAnimateZoomOut from "@/icons/icon_animate_zoom_out.svg";
import IconApiDoc from "@/icons/icon_api_doc.svg";
import IconApplyAll from "@/icons/icon_apply_all.svg";
import IconApplyAllModified from "@/icons/icon_apply_all_modified.svg";
import IconApplySingleModified from "@/icons/icon_apply_single_modified.svg";
import IconArrowBottom from "@/icons/icon_arrow_bottom.svg";
import IconArrowDown from "@/icons/icon_arrow_down.svg";
import IconArrowLeft from "@/icons/icon_arrow_left.svg";
import IconArrowLeftStart from "@/icons/icon_arrow_left_start.svg";
import IconArrowRight from "@/icons/icon_arrow_right.svg";
import IconArrowRightEnd from "@/icons/icon_arrow_right_end.svg";
import IconArrowTop from "@/icons/icon_arrow_top.svg";
import IconArrowUp from "@/icons/icon_arrow_up.svg";
import IconAsset from "@/icons/icon_asset.svg";
import IconAsset22 from "@/icons/icon_asset_22.svg";
import IconAudioConverter from "@/icons/icon_audio_converter.svg";
import IconAudioConverterAudioFile from "@/icons/icon_audio_converter_audio_file.svg";
import IconAudioPlayerDownload from "@/icons/icon_audio_player_download.svg";
import IconAudioPlayerMenu from "@/icons/icon_audio_player_menu.svg";
import IconAudioPlayerPause from "@/icons/icon_audio_player_pause.svg";
import IconAudioPlayerPlay from "@/icons/icon_audio_player_play.svg";
import IconAudioPlayerRate from "@/icons/icon_audio_player_rate.svg";
import IconAudioPlayerVolumeOff from "@/icons/icon_audio_player_volume_off.svg";
import IconAudioPlayerVolumeOn from "@/icons/icon_audio_player_volume_on.svg";
import IconAudioScript from "@/icons/icon_audio_script.svg";
import IconAudioScriptEditorRecord from "@/icons/icon_audio_script_editor_record.svg";
import IconAudioScriptEditorRemove from "@/icons/icon_audio_script_editor_remove.svg";
import IconAudioScriptEditorUpload from "@/icons/icon_audio_script_editor_upload.svg";
import IconAudioScriptRecord from "@/icons/icon_audio_script_record.svg";
import IconAudioScriptStopRecord from "@/icons/icon_audio_script_stop_record.svg";
import IconAudioTrim from "@/icons/icon_audio_trim.svg";
import IconAutomationMetadataDefault from "@/icons/icon_automation_metadata_default.svg";
import IconAvartar22 from "@/icons/icon_avartar_22.svg";
import IconAvatar from "@/icons/icon_avatar.svg";
import IconAvatarBtnNext from "@/icons/icon_avatar_btn_next.svg";
import IconAvatarPagePlay from "@/icons/icon_avatar_page_play.svg";
import IconAvatarPlay from "@/icons/icon_avatar_play.svg";
import IconBack from "@/icons/icon_back.svg";
import IconBackThin from "@/icons/icon_back_thin.svg";
import IconBackground from "@/icons/icon_background.svg";
import IconBackgroundToggle from "@/icons/icon_background_toggle.svg";
import IconBilling from "@/icons/icon_billing.svg";
import IconBody from "@/icons/icon_body.svg";
import IconBodyCircular from "@/icons/icon_body_circular.svg";
import IconBodyRectangle from "@/icons/icon_body_rectangle.svg";
import IconBold from "@/icons/icon_bold.svg";
import IconBrandKitFolder from "@/icons/icon_brandKit_folder.svg";
import IconBrandkitUpload from "@/icons/icon_brandkit_upload.svg";
import IconCaptionDownload from "@/icons/icon_caption_download.svg";
import IconCaretDown from "@/icons/icon_caret_down.svg";
import IconCaretRight from "@/icons/icon_caret_right.svg";
import IconChangeModel from "@/icons/icon_change_model.svg";
import IconChangePosition from "@/icons/icon_change_position.svg";
import IconChangeTemplateOrientation from "@/icons/icon_change_template_orientation.svg";
import IconChatgpt from "@/icons/icon_chatgpt.svg";
import IconChatgptActive from "@/icons/icon_chatgpt_active.svg";
import IconCheck from "@/icons/icon_check.svg";
import IconCheckClipClose from "@/icons/icon_check_clip_close.svg";
import IconCircleLoading from "@/icons/icon_circle_loading.svg";
import IconCircularWhite from "@/icons/icon_circular_white.svg";
import IconClock from "@/icons/icon_clock.svg";
import IconClose from "@/icons/icon_close.svg";
import IconCloseSmall from "@/icons/icon_close_small.svg";
import IconCode from "@/icons/icon_code.svg";
import IconConversationDefaultAvatar from "@/icons/icon_conversation_default_avatar.svg";
import IconConversationPlus from "@/icons/icon_conversation_plus.svg";
import IconCopy from "@/icons/icon_copy.svg";
import IconCopy22 from "@/icons/icon_copy_22.svg";
import IconCrop from "@/icons/icon_crop.svg";
import IconCropCancel from "@/icons/icon_crop_cancel.svg";
import IconCropCheck from "@/icons/icon_crop_check.svg";
import IconCropReset from "@/icons/icon_crop_reset.svg";
import IconCrown from "@/icons/icon_crown.svg";
import IconCut22 from "@/icons/icon_cut_22.svg";
import IconDashboardAvatar from "@/icons/icon_dashboard_avatar.svg";
import IconDashboardAvatarSelected from "@/icons/icon_dashboard_avatar_selected.svg";
import IconDashboardChromakeyProject from "@/icons/icon_dashboard_chromakey_project.svg";
import IconDashboardDefaultTemplateProject from "@/icons/icon_dashboard_default_template_project.svg";
import IconDashboardForYou from "@/icons/icon_dashboard_forYou.svg";
import IconDashboardForYouSelected from "@/icons/icon_dashboard_forYou_selected.svg";
import IconDashboardGenerator from "@/icons/icon_dashboard_generator.svg";
import IconDashboardGeneratorSelected from "@/icons/icon_dashboard_generator_selected.svg";
import IconDashboardHistory from "@/icons/icon_dashboard_history.svg";
import IconDashboardMainHeaderUpload from "@/icons/icon_dashboard_mainHeader_upload.svg";
import IconDashboardMarketing from "@/icons/icon_dashboard_marketing.svg";
import IconDashboardMarketingSelected from "@/icons/icon_dashboard_marketing_selected.svg";
import IconDashboardMember from "@/icons/icon_dashboard_member.svg";
import IconDashboardNewFolder from "@/icons/icon_dashboard_new_folder.svg";
import IconDashboardPromptMore from "@/icons/icon_dashboard_prompt_more.svg";
import IconDashboardSns from "@/icons/icon_dashboard_sns.svg";
import IconDashboardSnsSelected from "@/icons/icon_dashboard_sns_selected.svg";
import IconDashboardTool from "@/icons/icon_dashboard_tool.svg";
import IconDashboardToolSelected from "@/icons/icon_dashboard_tool_selected.svg";
import IconDashboardUploadPptProject from "@/icons/icon_dashboard_upload_ppt_project.svg";
import IconDashboardVoice from "@/icons/icon_dashboard_voice.svg";
import IconDashboardVoiceSelected from "@/icons/icon_dashboard_voice_selected.svg";
import IconDelete from "@/icons/icon_delete.svg";
import IconDiamond from "@/icons/icon_diamond.svg";
import IconDiamond16 from "@/icons/icon_diamond_16.svg";
import IconDictionary from "@/icons/icon_dictionary.svg";
import IconDocument from "@/icons/icon_document.svg";
import IconDocuments from "@/icons/icon_documents.svg";
import IconDownloadingArrow from "@/icons/icon_downloading_arrow.svg";
import IconDownloadingCheck from "@/icons/icon_downloading_check.svg";
import IconDownloadingError from "@/icons/icon_downloading_error.svg";
import IconDownloadingNeutral from "@/icons/icon_downloading_neutral.svg";
import IconDreamavatarUpload from "@/icons/icon_dreamavatar_upload.svg";
import IconDuplicate from "@/icons/icon_duplicate.svg";
import IconDuplicate22 from "@/icons/icon_duplicate_22.svg";
import IconDuplicateProject from "@/icons/icon_duplicate_project.svg";
import IconEdit from "@/icons/icon_edit.svg";
import IconEditBold from "@/icons/icon_edit_bold.svg";
import IconEffect from "@/icons/icon_effect.svg";
import IconEnterFullscreen from "@/icons/icon_enter_fullscreen.svg";
import IconExclamationCircle from "@/icons/icon_exclamation_circle.svg";
import IconExitFullscreen from "@/icons/icon_exit_fullscreen.svg";
import IconExportFolder from "@/icons/icon_export_folder.svg";
import IconFold from "@/icons/icon_fold.svg";
import IconFolderMove from "@/icons/icon_folder_move.svg";
import IconGenerate from "@/icons/icon_generate.svg";
import IconGptGenerate from "@/icons/icon_gpt_generate.svg";
import IconGroup22 from "@/icons/icon_group_22.svg";
import IconHamburger from "@/icons/icon_hamburger.svg";
import IconHeart from "@/icons/icon_heart.svg";
import IconHeartFill from "@/icons/icon_heart_fill.svg";
import IconHome from "@/icons/icon_home.svg";
import IconInfoCircle from "@/icons/icon_info_circle.svg";
import IconInfoCircle16 from "@/icons/icon_info_circle_16.svg";
import IconInformationLight from "@/icons/icon_information-light.svg";
import IconItalic from "@/icons/icon_italic.svg";
import IconLandscape from "@/icons/icon_landscape.svg";
import IconLayerPosition22 from "@/icons/icon_layer_position_22.svg";
import IconLeftAngle90 from "@/icons/icon_left_angle_90.svg";
import IconList from "@/icons/icon_list.svg";
import IconLock from "@/icons/icon_lock.svg";
import IconLock22 from "@/icons/icon_lock_22.svg";
import IconLogoApplyAll from "@/icons/icon_logo_applyAll.svg";
import IconLogoApplySingle from "@/icons/icon_logo_applySingle.svg";
import IconLoop from "@/icons/icon_loop.svg";
import IconMedia22 from "@/icons/icon_media_22.svg";
import IconMediaConverterConvertIcon from "@/icons/icon_media_converter_convert_icon.svg";
import IconMic from "@/icons/icon_mic.svg";
import IconMobile from "@/icons/icon_mobile.svg";
import IconMobileBack from "@/icons/icon_mobile_back.svg";
import IconModel from "@/icons/icon_model.svg";
import IconModelDeleteScriptPlus from "@/icons/icon_model_delete_script_plus.svg";
import IconModelGesture from "@/icons/icon_model_gesture.svg";
import IconModelMultipleSendTag from "@/icons/icon_model_multiple_send_tag.svg";
import IconModelPlay from "@/icons/icon_model_play.svg";
import IconModels from "@/icons/icon_models.svg";
import IconMore from "@/icons/icon_more.svg";
import IconMoveProjectFolder from "@/icons/icon_move_project_folder.svg";
import IconMoveProjectWorkspace from "@/icons/icon_move_project_workspace.svg";
import IconMusic from "@/icons/icon_music.svg";
import IconNext from "@/icons/icon_next.svg";
import IconNextArrow from "@/icons/icon_next_arrow.svg";
import IconOpacity from "@/icons/icon_opacity.svg";
import IconPaste22 from "@/icons/icon_paste_22.svg";
import IconPause from "@/icons/icon_pause.svg";
import IconPauseCircle from "@/icons/icon_pause_circle.svg";
import IconPlay from "@/icons/icon_play.svg";
import IconPlayCircle from "@/icons/icon_play_circle.svg";
import IconPlayerPlay from "@/icons/icon_player_play.svg";
import IconPlayerStop from "@/icons/icon_player_stop.svg";
import IconPlayfilled from "@/icons/icon_playfilled.svg";
import IconPlus from "@/icons/icon_plus.svg";
import IconPortrait from "@/icons/icon_portrait.svg";
import IconPosition from "@/icons/icon_position.svg";
import IconPptToVideo from "@/icons/icon_ppt_to_video.svg";
import IconPrev from "@/icons/icon_prev.svg";
import IconPrevArrow from "@/icons/icon_prev_arrow.svg";
import IconPreviewBtnPause from "@/icons/icon_preview_btn_pause.svg";
import IconPreviewBtnPlay from "@/icons/icon_preview_btn_play.svg";
import IconPreviewBtnStop from "@/icons/icon_preview_btn_stop.svg";
import IconPreviewPause from "@/icons/icon_preview_pause.svg";
import IconPreviewPauseTransparent from "@/icons/icon_preview_pause_transparent.svg";
import IconPreviewPlay from "@/icons/icon_preview_play.svg";
import IconPreviewPlayTransparent from "@/icons/icon_preview_play_transparent.svg";
import IconProfileSetting from "@/icons/icon_profile_setting.svg";
import IconProject from "@/icons/icon_project.svg";
import IconProjectDownload from "@/icons/icon_project_download.svg";
import IconProjectPublic from "@/icons/icon_project_public.svg";
import IconProofreadLanguageSelectDropdown from "@/icons/icon_proofread_language_select_dropdown.svg";
import IconProofreadNoSegment from "@/icons/icon_proofread_no_segment.svg";
import IconProofreadSegmentDelete from "@/icons/icon_proofread_segment_delete.svg";
import IconProofreadSegmentError from "@/icons/icon_proofread_segment_error.svg";
import IconProofreadSegmentHidden from "@/icons/icon_proofread_segment_hidden.svg";
import IconProofreadSegmentPause from "@/icons/icon_proofread_segment_pause.svg";
import IconProofreadSegmentPlay from "@/icons/icon_proofread_segment_play.svg";
import IconProofreadSegmentRollback from "@/icons/icon_proofread_segment_rollback.svg";
import IconProofreadSegmentSoundWave from "@/icons/icon_proofread_segment_sound_wave.svg";
import IconProofreadSegmentVisible from "@/icons/icon_proofread_segment_visible.svg";
import IconProofreadSegmentWarning from "@/icons/icon_proofread_segment_warning.svg";
import IconProofreadTranslationError from "@/icons/icon_proofread_translation_error.svg";
import IconProofreadTranslationLoading from "@/icons/icon_proofread_translation_loading.svg";
import IconProofreadTranslationSelected from "@/icons/icon_proofread_translation_selected.svg";
import IconProofreadTranslationWarning from "@/icons/icon_proofread_translation_warning.svg";
import IconProofreadUpdate from "@/icons/icon_proofread_update.svg";
import IconProofreadVoiceSelectSoundWave from "@/icons/icon_proofread_voice_select_sound_wave.svg";
import IconRandom from "@/icons/icon_random.svg";
import IconRatioLandscape from "@/icons/icon_ratio_landscape.svg";
import IconRatioPortrait from "@/icons/icon_ratio_portrait.svg";
import IconRecord from "@/icons/icon_record.svg";
import IconRecordLarge from "@/icons/icon_record_large.svg";
import IconRecordScreen from "@/icons/icon_record_screen.svg";
import IconRecording from "@/icons/icon_recording.svg";
import IconRedCircle from "@/icons/icon_red_circle.svg";
import IconRedo from "@/icons/icon_redo.svg";
import IconRefresh from "@/icons/icon_refresh.svg";
import IconRightAngle90 from "@/icons/icon_right_angle_90.svg";
import IconScormPublished from "@/icons/icon_scorm_published.svg";
import IconScormRepublish from "@/icons/icon_scorm_republish.svg";
import IconScreenRecorderEditingControlMenuDelete from "@/icons/icon_screen_recorder_editing_control_menu_delete.svg";
import IconScreenRecorderEditingControlMenuPause from "@/icons/icon_screen_recorder_editing_control_menu_pause.svg";
import IconScreenRecorderEditingControlMenuPlay from "@/icons/icon_screen_recorder_editing_control_menu_play.svg";
import IconScreenRecorderEditingControlMenuReset from "@/icons/icon_screen_recorder_editing_control_menu_reset.svg";
import IconScreenRecorderEditingControlMenuSoundOff from "@/icons/icon_screen_recorder_editing_control_menu_sound_off.svg";
import IconScreenRecorderEditingControlMenuSoundOn from "@/icons/icon_screen_recorder_editing_control_menu_sound_on.svg";
import IconScreenRecorderEditingControlMenuSplit from "@/icons/icon_screen_recorder_editing_control_menu_split.svg";
import IconScreenRecorderEditingControlMenuZoomIn from "@/icons/icon_screen_recorder_editing_control_menu_zoom_in.svg";
import IconScreenRecorderEditingControlMenuZoomOut from "@/icons/icon_screen_recorder_editing_control_menu_zoom_out.svg";
import IconScreenRecorderEditingPreviewClose from "@/icons/icon_screen_recorder_editing_preview_close.svg";
import IconScreenRecorderEditingPreviewDownload from "@/icons/icon_screen_recorder_editing_preview_download.svg";
import IconScreenRecorderEditingSideMenuBackground from "@/icons/icon_screen_recorder_editing_side_menu_background.svg";
import IconScreenRecorderEditingSideMenuClose from "@/icons/icon_screen_recorder_editing_side_menu_close.svg";
import IconScreenRecorderEditingSideMenuColorPicker from "@/icons/icon_screen_recorder_editing_side_menu_color_picker.svg";
import IconScreenRecorderEditingSideMenuLayout from "@/icons/icon_screen_recorder_editing_side_menu_layout.svg";
import IconScreenRecorderEditingSideMenuModel from "@/icons/icon_screen_recorder_editing_side_menu_model.svg";
import IconScreenRecorderEditingSideMenuPlay from "@/icons/icon_screen_recorder_editing_side_menu_play.svg";
import IconScreenRecorderEditingSideMenuRemove from "@/icons/icon_screen_recorder_editing_side_menu_remove.svg";
import IconScreenRecorderEditingSideMenuSearch from "@/icons/icon_screen_recorder_editing_side_menu_search.svg";
import IconScreenRecorderEditingSideMenuTrim from "@/icons/icon_screen_recorder_editing_side_menu_trim.svg";
import IconScreenRecorderRecordingButtonRecentProject from "@/icons/icon_screen_recorder_recording_button_recent_project.svg";
import IconScreenRecorderRecordingButtonShareScreen from "@/icons/icon_screen_recorder_recording_button_share_screen.svg";
import IconScreenRecorderRecordingGnbAvatarOff from "@/icons/icon_screen_recorder_recording_gnb_avatar_off.svg";
import IconScreenRecorderRecordingGnbAvatarOn from "@/icons/icon_screen_recorder_recording_gnb_avatar_on.svg";
import IconScreenRecorderRecordingGnbButtonList from "@/icons/icon_screen_recorder_recording_gnb_button_list.svg";
import IconScreenRecorderRecordingGnbCamOff from "@/icons/icon_screen_recorder_recording_gnb_cam_off.svg";
import IconScreenRecorderRecordingGnbCamOn from "@/icons/icon_screen_recorder_recording_gnb_cam_on.svg";
import IconScreenRecorderRecordingGnbCancel from "@/icons/icon_screen_recorder_recording_gnb_cancel.svg";
import IconScreenRecorderRecordingGnbMicOff from "@/icons/icon_screen_recorder_recording_gnb_mic_off.svg";
import IconScreenRecorderRecordingGnbMicOn from "@/icons/icon_screen_recorder_recording_gnb_mic_on.svg";
import IconScreenRecorderRecordingGnbRestart from "@/icons/icon_screen_recorder_recording_gnb_restart.svg";
import IconScreenRecorderRecordingGnbScreen from "@/icons/icon_screen_recorder_recording_gnb_screen.svg";
import IconScrollLeft from "@/icons/icon_scroll_left.svg";
import IconScrollRight from "@/icons/icon_scroll_right.svg";
import IconSearch from "@/icons/icon_search.svg";
import IconSelectLayer22 from "@/icons/icon_select_layer_22.svg";
import IconSend from "@/icons/icon_send.svg";
import IconShadow from "@/icons/icon_shadow.svg";
import IconShape22 from "@/icons/icon_shape_22.svg";
import IconShareCommentDelete from "@/icons/icon_share_comment_delete.svg";
import IconShareCommentEdit from "@/icons/icon_share_comment_edit.svg";
import IconShuffle from "@/icons/icon_shuffle.svg";
import IconShuffleBlue from "@/icons/icon_shuffle_blue.svg";
import IconShutterstockInfo from "@/icons/icon_shutterstock_info.svg";
import IconSound from "@/icons/icon_sound.svg";
import IconSubtitleAlert from "@/icons/icon_subtitle_alert.svg";
import IconSubtitleAlertClose from "@/icons/icon_subtitle_alert_close.svg";
import IconSubtitleGenerate from "@/icons/icon_subtitle_generate.svg";
import IconSubtitlePause from "@/icons/icon_subtitle_pause.svg";
import IconSubtitlePlay from "@/icons/icon_subtitle_play.svg";
import IconSubtitlePlus from "@/icons/icon_subtitle_plus.svg";
import IconSubtitlePlusLine from "@/icons/icon_subtitle_plus_line.svg";
import IconSubtitleQuickBefore from "@/icons/icon_subtitle_quick_before.svg";
import IconSubtitleQuickGenerate from "@/icons/icon_subtitle_quick_generate.svg";
import IconSubtitleRemove from "@/icons/icon_subtitle_remove.svg";
import IconTagLine from "@/icons/icon_tag_line.svg";
import IconTemplate from "@/icons/icon_template.svg";
import IconTempo from "@/icons/icon_tempo.svg";
import IconText22 from "@/icons/icon_text_22.svg";
import IconTextScript from "@/icons/icon_text_script.svg";
import IconTextToSpeech from "@/icons/icon_text_to_speech.svg";
import IconTopicToVideoSampleArrow from "@/icons/icon_topic_to_video_sample_arrow.svg";
import IconTransition from "@/icons/icon_transition.svg";
import IconTransitionBlackFade from "@/icons/icon_transition_black_fade.svg";
import IconTransitionCircleEraser from "@/icons/icon_transition_circle_eraser.svg";
import IconTransitionCrossFade from "@/icons/icon_transition_cross_fade.svg";
import IconTransitionExpansion from "@/icons/icon_transition_expansion.svg";
import IconTransitionGateHorizontal from "@/icons/icon_transition_gate_horizontal.svg";
import IconTransitionGateVertical from "@/icons/icon_transition_gate_vertical.svg";
import IconTransitionNoTransition from "@/icons/icon_transition_no_transition.svg";
import IconTransitionPlus from "@/icons/icon_transition_plus.svg";
import IconTransitionPushDown from "@/icons/icon_transition_push_down.svg";
import IconTransitionPushLeft from "@/icons/icon_transition_push_left.svg";
import IconTransitionPushRight from "@/icons/icon_transition_push_right.svg";
import IconTransitionPushUp from "@/icons/icon_transition_push_up.svg";
import IconTransitionReduction from "@/icons/icon_transition_reduction.svg";
import IconTransitionWhiteFade from "@/icons/icon_transition_white_fade.svg";
import IconTransitionWipeDown from "@/icons/icon_transition_wipe_down.svg";
import IconTransitionWipeLeft from "@/icons/icon_transition_wipe_left.svg";
import IconTransitionWipeRight from "@/icons/icon_transition_wipe_right.svg";
import IconTransitionWipeUp from "@/icons/icon_transition_wipe_up.svg";
import IconTranslate from "@/icons/icon_translate.svg";
import IconTranslateBtn from "@/icons/icon_translate_btn.svg";
import IconTranslation from "@/icons/icon_translation.svg";
import IconTrash from "@/icons/icon_trash.svg";
import IconTrashCan from "@/icons/icon_trash_can.svg";
import IconTrim from "@/icons/icon_trim.svg";
import IconTryThisVoice from "@/icons/icon_try_this_voice.svg";
import IconTutorial from "@/icons/icon_tutorial.svg";
import IconUnderLine from "@/icons/icon_under_line.svg";
import IconUndo from "@/icons/icon_undo.svg";
import IconUnfold from "@/icons/icon_unfold.svg";
import IconUngroup22 from "@/icons/icon_ungroup_22.svg";
import IconUnlock22 from "@/icons/icon_unlock_22.svg";
import IconUp from "@/icons/icon_up.svg";
import IconUpload from "@/icons/icon_upload.svg";
import IconUploadCloud from "@/icons/icon_upload_cloud.svg";
import IconUploadFile from "@/icons/icon_upload_file.svg";
import IconUser from "@/icons/icon_user.svg";
import IconUserTemplateBtn from "@/icons/icon_user_template_btn.svg";
import IconVector from "@/icons/icon_vector.svg";
import IconVideoConverter from "@/icons/icon_video_converter.svg";
import IconVideoDownloader from "@/icons/icon_video_downloader.svg";
import IconVideoDownloaderDashboardCheck from "@/icons/icon_video_downloader_dashboard_check.svg";
import IconVideoDownloaderDownload from "@/icons/icon_video_downloader_download.svg";
import IconVideoDownloaderUrlInput from "@/icons/icon_video_downloader_url_input.svg";
import IconVideoSetCdnLink from "@/icons/icon_video_set_cdn_link.svg";
import IconVideoSetDownload from "@/icons/icon_video_set_download.svg";
import IconVideoSetDownloadThumbnail from "@/icons/icon_video_set_download_thumbnail.svg";
import IconVideoSetEdit from "@/icons/icon_video_set_edit.svg";
import IconVideoSetScormPublish from "@/icons/icon_video_set_scorm_publish.svg";
import IconVideoSetScormRepublish from "@/icons/icon_video_set_scorm_republish.svg";
import IconVideoSetScormUnpublish from "@/icons/icon_video_set_scorm_unpublish.svg";
import IconVoice from "@/icons/icon_voice.svg";
import IconVoiceWhite from "@/icons/icon_voice_white.svg";
import IconVoices from "@/icons/icon_voices.svg";
import IconVolume from "@/icons/icon_volume.svg";
import IconVolumeMid from "@/icons/icon_volume_mid.svg";
import IconVolumeMute from "@/icons/icon_volume_mute.svg";
import IconWebEarth from "@/icons/icon_web_earth.svg";
import IconWhiteLogout from "@/icons/icon_white_logout.svg";
import IconWorkspaceAddMember from "@/icons/icon_workspace_add_member.svg";
import IconWorkspaceChecked from "@/icons/icon_workspace_checked.svg";
import IconWorkspaceCreate from "@/icons/icon_workspace_create.svg";
import IconWorkspaceHyperlink from "@/icons/icon_workspace_hyperlink.svg";
import IconWorkspaceInvite from "@/icons/icon_workspace_invite.svg";
import IconWorkspaceMenu from "@/icons/icon_workspace_menu.svg";
import IconWorkspaceProjectShare from "@/icons/icon_workspace_project_share.svg";
import IconWorkspacePublic from "@/icons/icon_workspace_public.svg";
import IconWorkspaceShareProject from "@/icons/icon_workspace_share_project.svg";
import IconWorkspaceUsers from "@/icons/icon_workspace_users.svg";
import IconWorkspaceWebInvite from "@/icons/icon_workspace_web_invite.svg";
import LogoDbSymbol from "@/icons/logo_db_symbol.svg";
import LogoDeepbrain from "@/icons/logo_deepbrain.svg";
import LogoDeepbrainai from "@/icons/logo_deepbrainai.svg";
import LogoPixabay from "@/icons/logo_pixabay.svg";
import LogoShutterstock from "@/icons/logo_shutterstock.svg";
import MenuAssets from "@/icons/menu_assets.svg";
import MenuGenerate from "@/icons/menu_generate.svg";
import MenuImg from "@/icons/menu_img.svg";
import MenuModel from "@/icons/menu_model.svg";
import MenuProjects from "@/icons/menu_projects.svg";
import MenuShapes from "@/icons/menu_shapes.svg";
import MenuSubtitle from "@/icons/menu_subtitle.svg";
import MenuTemplate from "@/icons/menu_template.svg";
import MenuText from "@/icons/menu_text.svg";
import MenuTransition from "@/icons/menu_transition.svg";
import PushIcon from "@/icons/push_icon.svg";
import UpgradeIcon from "@/icons/upgrade_icon.svg";

export {
  IconProhibition,
  Icon404,
  IconEraseCircleOut,
  IconEraseDownOut,
  IconEraseLeftOut,
  IconEraseRightOut,
  IconEraseUpOut,
  IconFadeBlackOut,
  IconFadeCollapseIn,
  IconFadeCrossOutIn,
  IconFadeExpendOut,
  IconFadeWhiteOut,
  IconPushDownupOutIn,
  IconPushLeftOutIn,
  IconPushRightOutIn,
  IconPushUpdownOutIn,
  IconSplitHorizontalOut,
  IconSplitVerticalOut,
  IconAccount,
  IconAdd,
  IconAiModelPositionButton,
  IconAiModelScaleButton,
  IconAiScriptGeneratorCreateVideo,
  IconAiScriptGeneratorTopicInput,
  IconAistudios,
  IconAlign,
  IconAlign22,
  IconAlignBottom,
  IconAlignCenter,
  IconAlignLeft,
  IconAlignMiddle,
  IconAlignRight,
  IconAlignTop,
  IconAmoonCommentCots,
  IconAnimate,
  IconAnimateFadeIn,
  IconAnimateFadeOut,
  IconAnimateInDown,
  IconAnimateInLeft,
  IconAnimateInRight,
  IconAnimateInUp,
  IconAnimateNo,
  IconAnimateOutDown,
  IconAnimateOutLeft,
  IconAnimateOutRight,
  IconAnimateOutUp,
  IconAnimateZoomIn,
  IconAnimateZoomOut,
  IconApiDoc,
  IconApplyAll,
  IconApplyAllModified,
  IconApplySingleModified,
  IconArrowBottom,
  IconArrowDown,
  IconArrowLeft,
  IconArrowLeftStart,
  IconArrowRight,
  IconArrowRightEnd,
  IconArrowTop,
  IconArrowUp,
  IconAsset,
  IconAsset22,
  IconAudioConverter,
  IconAudioConverterAudioFile,
  IconAudioPlayerDownload,
  IconAudioPlayerMenu,
  IconAudioPlayerPause,
  IconAudioPlayerPlay,
  IconAudioPlayerRate,
  IconAudioPlayerVolumeOff,
  IconAudioPlayerVolumeOn,
  IconAudioScript,
  IconAudioScriptEditorRecord,
  IconAudioScriptEditorRemove,
  IconAudioScriptEditorUpload,
  IconAudioScriptRecord,
  IconAudioScriptStopRecord,
  IconAudioTrim,
  IconAutomationMetadataDefault,
  IconAvartar22,
  IconAvatar,
  IconAvatarBtnNext,
  IconAvatarPagePlay,
  IconAvatarPlay,
  IconBack,
  IconBackThin,
  IconBackground,
  IconBackgroundToggle,
  IconBilling,
  IconBody,
  IconBodyCircular,
  IconBodyRectangle,
  IconBold,
  IconBrandKitFolder,
  IconBrandkitUpload,
  IconCaptionDownload,
  IconCaretDown,
  IconCaretRight,
  IconChangeModel,
  IconChangePosition,
  IconChangeTemplateOrientation,
  IconChatgpt,
  IconChatgptActive,
  IconCheck,
  IconCheckClipClose,
  IconCircleLoading,
  IconCircularWhite,
  IconClock,
  IconClose,
  IconCloseSmall,
  IconCode,
  IconConversationDefaultAvatar,
  IconConversationPlus,
  IconCopy,
  IconCopy22,
  IconCrop,
  IconCropCancel,
  IconCropCheck,
  IconCropReset,
  IconCrown,
  IconCut22,
  IconDashboardAvatar,
  IconDashboardAvatarSelected,
  IconDashboardChromakeyProject,
  IconDashboardDefaultTemplateProject,
  IconDashboardForYou,
  IconDashboardForYouSelected,
  IconDashboardGenerator,
  IconDashboardGeneratorSelected,
  IconDashboardHistory,
  IconDashboardMainHeaderUpload,
  IconDashboardMarketing,
  IconDashboardMarketingSelected,
  IconDashboardMember,
  IconDashboardNewFolder,
  IconDashboardPromptMore,
  IconDashboardSns,
  IconDashboardSnsSelected,
  IconDashboardTool,
  IconDashboardToolSelected,
  IconDashboardUploadPptProject,
  IconDashboardVoice,
  IconDashboardVoiceSelected,
  IconDelete,
  IconDiamond,
  IconDiamond16,
  IconDictionary,
  IconDocument,
  IconDocuments,
  IconDownloadingArrow,
  IconDownloadingCheck,
  IconDownloadingError,
  IconDownloadingNeutral,
  IconDreamavatarUpload,
  IconDuplicate,
  IconDuplicate22,
  IconDuplicateProject,
  IconEdit,
  IconEditBold,
  IconEffect,
  IconEnterFullscreen,
  IconExclamationCircle,
  IconExitFullscreen,
  IconExportFolder,
  IconFold,
  IconFolderMove,
  IconGenerate,
  IconGptGenerate,
  IconGroup22,
  IconHamburger,
  IconHeart,
  IconHeartFill,
  IconHome,
  IconInfoCircle,
  IconInfoCircle16,
  IconInformationLight,
  IconItalic,
  IconLandscape,
  IconLayerPosition22,
  IconLeftAngle90,
  IconList,
  IconLock,
  IconLock22,
  IconLogoApplyAll,
  IconLogoApplySingle,
  IconLoop,
  IconMedia22,
  IconMediaConverterConvertIcon,
  IconMic,
  IconMobile,
  IconMobileBack,
  IconModel,
  IconModelDeleteScriptPlus,
  IconModelGesture,
  IconModelMultipleSendTag,
  IconModelPlay,
  IconModels,
  IconMore,
  IconMoveProjectFolder,
  IconMoveProjectWorkspace,
  IconMusic,
  IconNext,
  IconNextArrow,
  IconOpacity,
  IconPaste22,
  IconPause,
  IconPauseCircle,
  IconPlay,
  IconPlayCircle,
  IconPlayerPlay,
  IconPlayerStop,
  IconPlayfilled,
  IconPlus,
  IconPortrait,
  IconPosition,
  IconPptToVideo,
  IconPrev,
  IconPrevArrow,
  IconPreviewBtnPause,
  IconPreviewBtnPlay,
  IconPreviewBtnStop,
  IconPreviewPause,
  IconPreviewPauseTransparent,
  IconPreviewPlay,
  IconPreviewPlayTransparent,
  IconProfileSetting,
  IconProject,
  IconProjectDownload,
  IconProjectPublic,
  IconProofreadLanguageSelectDropdown,
  IconProofreadNoSegment,
  IconProofreadSegmentDelete,
  IconProofreadSegmentError,
  IconProofreadSegmentHidden,
  IconProofreadSegmentPause,
  IconProofreadSegmentPlay,
  IconProofreadSegmentRollback,
  IconProofreadSegmentSoundWave,
  IconProofreadSegmentVisible,
  IconProofreadSegmentWarning,
  IconProofreadTranslationError,
  IconProofreadTranslationLoading,
  IconProofreadTranslationSelected,
  IconProofreadTranslationWarning,
  IconProofreadUpdate,
  IconProofreadVoiceSelectSoundWave,
  IconRandom,
  IconRatioLandscape,
  IconRatioPortrait,
  IconRecord,
  IconRecordLarge,
  IconRecordScreen,
  IconRecording,
  IconRedCircle,
  IconRedo,
  IconRefresh,
  IconRightAngle90,
  IconScormPublished,
  IconScormRepublish,
  IconScreenRecorderEditingControlMenuDelete,
  IconScreenRecorderEditingControlMenuPause,
  IconScreenRecorderEditingControlMenuPlay,
  IconScreenRecorderEditingControlMenuReset,
  IconScreenRecorderEditingControlMenuSoundOff,
  IconScreenRecorderEditingControlMenuSoundOn,
  IconScreenRecorderEditingControlMenuSplit,
  IconScreenRecorderEditingControlMenuZoomIn,
  IconScreenRecorderEditingControlMenuZoomOut,
  IconScreenRecorderEditingPreviewClose,
  IconScreenRecorderEditingPreviewDownload,
  IconScreenRecorderEditingSideMenuBackground,
  IconScreenRecorderEditingSideMenuClose,
  IconScreenRecorderEditingSideMenuColorPicker,
  IconScreenRecorderEditingSideMenuLayout,
  IconScreenRecorderEditingSideMenuModel,
  IconScreenRecorderEditingSideMenuPlay,
  IconScreenRecorderEditingSideMenuRemove,
  IconScreenRecorderEditingSideMenuSearch,
  IconScreenRecorderEditingSideMenuTrim,
  IconScreenRecorderRecordingButtonRecentProject,
  IconScreenRecorderRecordingButtonShareScreen,
  IconScreenRecorderRecordingGnbAvatarOff,
  IconScreenRecorderRecordingGnbAvatarOn,
  IconScreenRecorderRecordingGnbButtonList,
  IconScreenRecorderRecordingGnbCamOff,
  IconScreenRecorderRecordingGnbCamOn,
  IconScreenRecorderRecordingGnbCancel,
  IconScreenRecorderRecordingGnbMicOff,
  IconScreenRecorderRecordingGnbMicOn,
  IconScreenRecorderRecordingGnbRestart,
  IconScreenRecorderRecordingGnbScreen,
  IconScrollLeft,
  IconScrollRight,
  IconSearch,
  IconSelectLayer22,
  IconSend,
  IconShadow,
  IconShape22,
  IconShareCommentDelete,
  IconShareCommentEdit,
  IconShuffle,
  IconShuffleBlue,
  IconShutterstockInfo,
  IconSound,
  IconSubtitleAlert,
  IconSubtitleAlertClose,
  IconSubtitleGenerate,
  IconSubtitlePause,
  IconSubtitlePlay,
  IconSubtitlePlus,
  IconSubtitlePlusLine,
  IconSubtitleQuickBefore,
  IconSubtitleQuickGenerate,
  IconSubtitleRemove,
  IconTagLine,
  IconTemplate,
  IconTempo,
  IconText22,
  IconTextScript,
  IconTextToSpeech,
  IconTopicToVideoSampleArrow,
  IconTransition,
  IconTransitionBlackFade,
  IconTransitionCircleEraser,
  IconTransitionCrossFade,
  IconTransitionExpansion,
  IconTransitionGateHorizontal,
  IconTransitionGateVertical,
  IconTransitionNoTransition,
  IconTransitionPlus,
  IconTransitionPushDown,
  IconTransitionPushLeft,
  IconTransitionPushRight,
  IconTransitionPushUp,
  IconTransitionReduction,
  IconTransitionWhiteFade,
  IconTransitionWipeDown,
  IconTransitionWipeLeft,
  IconTransitionWipeRight,
  IconTransitionWipeUp,
  IconTranslate,
  IconTranslateBtn,
  IconTranslation,
  IconTrash,
  IconTrashCan,
  IconTrim,
  IconTryThisVoice,
  IconTutorial,
  IconUnderLine,
  IconUndo,
  IconUnfold,
  IconUngroup22,
  IconUnlock22,
  IconUp,
  IconUpload,
  IconUploadCloud,
  IconUploadFile,
  IconUser,
  IconUserTemplateBtn,
  IconVector,
  IconVideoConverter,
  IconVideoDownloader,
  IconVideoDownloaderDashboardCheck,
  IconVideoDownloaderDownload,
  IconVideoDownloaderUrlInput,
  IconVideoSetCdnLink,
  IconVideoSetDownload,
  IconVideoSetDownloadThumbnail,
  IconVideoSetEdit,
  IconVideoSetScormPublish,
  IconVideoSetScormRepublish,
  IconVideoSetScormUnpublish,
  IconVoice,
  IconVoiceWhite,
  IconVoices,
  IconVolume,
  IconVolumeMid,
  IconVolumeMute,
  IconWebEarth,
  IconWhiteLogout,
  IconWorkspaceAddMember,
  IconWorkspaceChecked,
  IconWorkspaceCreate,
  IconWorkspaceHyperlink,
  IconWorkspaceInvite,
  IconWorkspaceMenu,
  IconWorkspaceProjectShare,
  IconWorkspacePublic,
  IconWorkspaceShareProject,
  IconWorkspaceUsers,
  IconWorkspaceWebInvite,
  LogoDbSymbol,
  LogoDeepbrain,
  LogoDeepbrainai,
  LogoPixabay,
  LogoShutterstock,
  MenuAssets,
  MenuGenerate,
  MenuImg,
  MenuModel,
  MenuProjects,
  MenuShapes,
  MenuSubtitle,
  MenuTemplate,
  MenuText,
  MenuTransition,
  PushIcon,
  UpgradeIcon
}
